import {CommitOptions, createStore, DispatchOptions, Store as VuexStore} from 'vuex';
import {mutations, Mutations} from '@/store/mutations';
import {state, State} from '@/store/state';
import {actions, Actions} from '@/store/actions';
import {getters, Getters} from '@/store/getters';

const strict = window.location.hostname.endsWith('.test');
if (strict) {
    console.warn('Strict Vuex is enabled! Site performance might be reduced.');
}

export default createStore({state, mutations, actions, getters, strict})

export type StoreType = Omit<VuexStore<State>, 'getters' | 'commit' | 'dispatch'> & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
        key: K,
        payload?: P,
        options?: CommitOptions
    ): ReturnType<Mutations[K]>;
} & {
    dispatch<K extends keyof Actions>(
        key: K,
        payload?: Parameters<Actions[K]>[1],
        options?: DispatchOptions
    ): ReturnType<Actions[K]>;
} & {
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>
    };
}
