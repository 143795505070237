import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "line-clamp-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_submenu_entry = _resolveComponent("submenu-entry")!

  return (_openBlock(), _createBlock(_component_submenu_entry, {
    path: _ctx.path,
    icon: _ctx.icon || undefined,
    color: _ctx.competence.menuColor
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.competence.name), 1)
    ]),
    _: 1
  }, 8, ["path", "icon", "color"]))
}