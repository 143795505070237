
import {defineComponent} from 'vue';
import {competenceMixin} from '@/router/competenceMixin';
import {Competence} from '@/data/api';
import {Rating} from '@/data/enums';
import RatingSymbol from '@/components/RatingSymbol.vue';
import Icon from '@/components/Icon.vue';
import CompetenceNavItem from '@/components/CompetenceNavItem.vue';

export default defineComponent({
	name: 'CompetenceResultNav',
	components: {CompetenceNavItem, RatingSymbol, Icon},
	mixins: [competenceMixin],
	data() {
		return {
			isOpen: false
		}
	},
	computed: {
		competencesToDisplay(): Competence[] {
			const activeCompetence = this.competence ? [this.competence] : [];
			return this.isOpen ? this.$store.state.competences ?? [] : activeCompetence;
		}
	},
	watch: {
		$route() {
			this.isOpen = false;
		},
	},
	methods: {
		isSelected(competence: Competence) {
			return competence.id === this.competence?.id;
		},
		getRating(competence: Competence): Rating | null {
			const len = Object.keys(competence.statements).length;
			if (len <= 0) {
				return null;
			}

			let total = 0;

			for (const uuid in competence.statements) {
				total += this.$store.state.answers[uuid];
			}

			return Math.round(total / len);
		},
	}
})
