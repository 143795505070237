import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main__content__inner" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: "block-wrapper page-content",
          innerHTML: _ctx.content
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}