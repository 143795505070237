
import {defineComponent} from 'vue'
import CompetenceDescriptionStand from '@/components/CompetenceDescriptionStand.vue';
import {competenceMixin} from '@/router/competenceMixin';
import Icon from '@/components/Icon.vue';
import ResultBlockWorkQuestions from '@/components/resultBlocks/ResultBlockWorkQuestions.vue';
import ResultBlockTips from '@/components/resultBlocks/ResultBlockTips.vue';
import ResultBlockExercises from '@/components/resultBlocks/ResultBlockExercises.vue';
import CompetenceInfoBlock from '@/components/CompetenceInfoBlock.vue';
import ContextStand from '@/components/ContextStand.vue';
import CompetenceResultNav from '@/components/CompetenceResultNav.vue';
import {Competence} from '@/data/api';
import {isSet} from '@/data/guardHelper';

export default defineComponent({
    name: 'ResultCompetence',
    components: {
        CompetenceResultNav,
        CompetenceInfoBlock,
        ResultBlockExercises,
        ResultBlockTips,
        CompetenceDescriptionStand,
        Icon,
        ResultBlockWorkQuestions,
        ContextStand
    },
    mixins: [competenceMixin],
    data() {
        return {
            selectedTab: 0,
        }
    },
    computed: {
        filePrefix(): string {
            let prefix = '';

            if (this.$store.state.contextGroup) {
                prefix += `${this.$store.state.contextGroup.groupName} `;
            }
            if (this.competence) {
                prefix += `${this.competence.name} `;
            }

            return prefix;
        },
        content() {
            const competence = this.competence as Competence | null;
            return {
                'workQuestions': competence?.reflectionContent.workQuestions,
                'exercises': competence?.reflectionContent.exercises,
                'tips': competence?.reflectionContent.tips
            } as const;
        }
    },
    methods: {
        scrollTo(element: HTMLElement) {
            element.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        selectTab(tabIndex: 0 | 1 | 2) {
            this.selectedTab = tabIndex;
            window.dispatchEvent(new Event('resize'));
        },
        isSet(val: unknown) {
            return isSet(val);
        }
    },
})
