import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "return-button" }
const _hoisted_2 = {
  key: 0,
  class: "block-wrapper"
}
const _hoisted_3 = { class: "content-wrapper-sm" }
const _hoisted_4 = { class: "title-width-dot d-flex justify-content-start align-items-start mb-3" }
const _hoisted_5 = { class: "title mb-2" }
const _hoisted_6 = { class: "rating-question-list pt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_survey_navigation_buttons = _resolveComponent("survey-navigation-buttons")!
  const _component_context_stand = _resolveComponent("context-stand")!
  const _component_competence_description_stand = _resolveComponent("competence-description-stand")!
  const _component_rating_question = _resolveComponent("rating-question")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_survey_navigation_buttons, { forwards: false })
    ]),
    (_ctx.competence)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createVNode(_component_context_stand),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: "dot mr-3 mb-2",
                style: _normalizeStyle({ backgroundColor: _ctx.competence.menuColor })
              }, null, 4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", { ref: "title" }, _toDisplayString(_ctx.competence.name), 513)
              ])
            ])
          ]),
          _createVNode(_component_competence_description_stand, { competence: _ctx.competence }, null, 8, ["competence"]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.competence.statements, (statement, key) => {
              return (_openBlock(), _createBlock(_component_rating_question, {
                key: key,
                statement: statement
              }, null, 8, ["statement"]))
            }), 128)),
            _createVNode(_component_survey_navigation_buttons, {
              class: "d-flex flex-nowrap justify-content-end align-items-center",
              backwards: false
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}