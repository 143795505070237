
import {defineComponent} from 'vue'
import CompetenceDescriptionStand from '@/components/CompetenceDescriptionStand.vue';
import ContextStand from '@/components/ContextStand.vue';
import RatingQuestion from '@/components/form/RatingQuestion.vue';
import {navigationMixin} from '@/router/navigationMixin';
import SurveyNavigationButtons from '@/components/SurveyNavigationButtons.vue';
import {competenceMixin} from '@/router/competenceMixin';
import {mobileHeaderHeight} from '@/data/constants';

export default defineComponent({
    name: 'CompetenceQuestions',
    components: {SurveyNavigationButtons, CompetenceDescriptionStand, ContextStand, RatingQuestion},
    mixins: [navigationMixin, competenceMixin],
	updated() {
		if (this.$refs.title instanceof HTMLElement) {
			const y = this.$refs.title.getBoundingClientRect().top + window.pageYOffset - mobileHeaderHeight;
			window.scrollTo({top: y, behavior: 'smooth'});
		}
	}
})
