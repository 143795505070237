import {ActionContext, ActionTree} from 'vuex';
import {State} from '@/store/state';
import {Mutations} from '@/store/mutations';
import api from '@/data/api';

export enum ActionResult {Error, Success, MissingContext}

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
    'fetchCompetences'({ commit }: AugmentedActionContext): Promise<ActionResult>;
}

export const actions: ActionTree<State, State> & Actions = {
    async 'fetchCompetences'({ commit, state }) {
        return new Promise((resolve) => {
            if (state.context) {
                api.getCompetences(state.context.id).then(response => {
                    commit('setCompetences', response.data);
                    resolve(ActionResult.Success);
                }).catch(err => {
                    console.error(err);
                    resolve(ActionResult.Error);
                });
            } else {
                resolve(ActionResult.MissingContext);
            }
        });
    },
}