
import {defineComponent} from 'vue'
import api, {Competence} from '@/data/api';
import {Rating} from '@/data/enums';
import SurveyNavigationButtons from '@/components/SurveyNavigationButtons.vue';
import Icon from '@/components/Icon.vue';
import ContextStand from '@/components/ContextStand.vue';
import BaseModal from '@/components/BaseModal.vue';
import RatingSymbol from '@/components/RatingSymbol.vue';

export default defineComponent({
	name: 'ResultOverview',
	components: {RatingSymbol, BaseModal, Icon, SurveyNavigationButtons, ContextStand},
	data: () => ({
		modalCompetence: undefined as Competence|undefined,
		contextTitle: null as string|null
	}),
	watch: {
		$route() {
			this.toggleModal();
		},
	},
	mounted() {
		let total = 0;
		for (const uuid in this.$store.state.answers) {
			total += this.$store.state.answers[uuid];
		}

		const contextId = this.$store.state.context?.id;
		if (contextId) {
			api.getContext(contextId).then(context => {
				this.contextTitle = context.data.resultOverviewTitle;
			});
		}
	},
	methods: {
		getRating(competence: Competence): Rating|null {
			const len = Object.keys(competence.statements).length;
			if (len <= 0) {
				return null;
			}

			let total = 0;

			for (const uuid in competence.statements) {
				total += this.$store.state.answers[uuid];
			}

			return Math.round(total / len);
		},
		getRatingText(competence: Competence): string {
			const rating = this.getRating(competence);
			if (rating) {
				return competence.resultText[rating];
			}

			return 'Vul alle vragen in om advies te krijgen.';
		},
		toggleModal(competence?: Competence) {
			this.modalCompetence = competence;
			document.body.classList.toggle('modal-open', !!competence);
		}
	},
})
