import axios, {AxiosResponse} from 'axios';
import {CompetenceStatus} from '@/data/enums';

// Any values received from the Api should be set to readonly
// Any values that we modify on the client should be optional
export interface Statement {
    readonly uuid: string;
    readonly question: string;
    readonly weight: number;
}

export interface Competence {
    readonly id: number;
    readonly name: string;
    readonly resultText: {[key: number]: string};
    readonly statements: {[key: string]: Statement};
    readonly menuColor: string;
    readonly description: string;
    readonly reflectionContent: ReflectionContent;
    readonly files: CompetenceFiles;
    status?: CompetenceStatus;
}

interface ReflectionContent {
    readonly exercises: {exercise: string}[];
    readonly tips: string|null;
    readonly workQuestions: string|null;
}

interface CompetenceFiles {
    readonly exercises: string|null;
    readonly workQuestions: string|null;
}

export interface ContentPage {
    readonly content: string;
}

export interface ContextGroup {
    readonly contexts: Context[];
    readonly groupName: string;
}

export interface Role {
    readonly id: number;
    readonly name: string;
}

export interface Context {
    readonly id: number;
    readonly name: string;
    readonly resultOverviewTitle: string|null;
}

// Methods
type AxiosPromise<T> = Promise<AxiosResponse<T>>;
export default {
    url(endpoint: string) {
        const target = `/wp/api/${endpoint}/`;

        if (window.location.origin.includes('localhost')) {
            return `http://loopbaantool.test${target}`;
        }

        return window.location.origin + target;
    },

    getCompetences(contextId: number): AxiosPromise<Competence[]> {
        return axios.get(`${this.url('competences')}?context=${contextId}`);
    },

    getContentPage(slug: string): AxiosPromise<ContentPage> {
        return axios.get(`${this.url('content-page')}?slug=${slug}`);
    },

    getRoles(): AxiosPromise<Role[]> {
        return axios.get(this.url('roles'));
    },

    getContextGroups(roleId: number): AxiosPromise<{[key: number]: ContextGroup}> {
        return axios.get(`${this.url(`context-groups`)}?role=${roleId}`);
    },

    getContext(contextId: number): AxiosPromise<Context> {
        return axios.get(`${this.url(`context`)}?context=${contextId}`);
    }
}
