import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }
const _hoisted_2 = { class: "main__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_CookieBanner = _resolveComponent("CookieBanner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderMenu),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterView)
      ])
    ]),
    (_ctx.cookieEnabled)
      ? (_openBlock(), _createBlock(_component_CookieBanner, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}