import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "more-info-stand rounded"
}
const _hoisted_2 = { class: "more-info-stand__button-text" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_ctx.competence.description)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "cursor-pointer d-flex flex-nowrap justify-content-between align-items-center",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.infoCollapse && _ctx.infoCollapse(...args)))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode(" Meer over " + _toDisplayString(_ctx.competence.name) + " ", 1),
            _createElementVNode("span", null, [
              _createVNode(_component_icon, { icon: "questionCircle" })
            ])
          ]),
          _createElementVNode("button", {
            class: _normalizeClass(["more-info-stand__button", [_ctx.collapseOpen ? '' : 'show']])
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_icon, { icon: "chevronDown" })
            ])
          ], 2)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["collapse", [_ctx.collapseOpen ? '' : 'show']])
        }, [
          _createElementVNode("div", {
            class: "py-2",
            innerHTML: _ctx.competence.description
          }, null, 8, _hoisted_3)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}