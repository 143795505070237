
import {defineComponent} from 'vue'
import {Competence} from '@/data/api';
import {CompetenceStatus} from '@/data/enums';
import SubmenuEntry from '@/components/SubmenuEntry.vue';

export default defineComponent({
    name: 'SubmenuCompetence',
    components: {SubmenuEntry},
    props: {
        competence: {type: Object as () => Competence, required: true},
    },
    computed: {
        path(): string {
            return `/scan/competentie/${this.competence.id}`;
        },
        icon() {
            if (this.competence.status === CompetenceStatus.Active) {
                return 'pencil';
            } else if (this.competence.status === CompetenceStatus.Done) {
                return 'check';
            }

            return null;
        },
    },
})
