
import {defineComponent} from 'vue'
import Icon from '@/components/Icon.vue';

export default defineComponent({
    name: 'SubmenuEntry',
    components: {Icon},
    props: {
        path: {type: String, required: true},
        icon: {type: String, default: null},
        color: {type: String, default: null}
    },
    computed: {
        isDisabled(): boolean {
            return !this.$store.state.steps.includes(this.path);
        }
    },
})
