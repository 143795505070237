import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "menu-navigation-sublist__link disabled" }
const _hoisted_3 = {
  key: 0,
  class: "icon"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isDisabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.color)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "sublist-accent opaque",
              style: _normalizeStyle({ backgroundColor: _ctx.color })
            }, null, 4))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createVNode(_component_icon, { icon: _ctx.icon }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        (_ctx.color)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "sublist-accent",
              style: _normalizeStyle({ backgroundColor: _ctx.color })
            }, null, 4))
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, {
          to: _ctx.path,
          class: "menu-navigation-sublist__link done"
        }, {
          default: _withCtx(() => [
            (_ctx.icon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createVNode(_component_icon, { icon: _ctx.icon }, null, 8, ["icon"])
                ]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["to"])
      ]))
}