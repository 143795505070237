
import api from '@/data/api'
import {defineComponent} from 'vue'

export default defineComponent({
    name: 'ContentPage',
    data() {
        return {
            content: ''
        }
    },
    computed: {
        slug() {
            const slug = this.$route.params['slug'];
            return typeof slug === 'string' ? slug : 'home';
        },
    },
    mounted() {
        this.load();
    },
    beforeUpdate() {
        this.load();
    },
    methods: {
        load() {
            api.getContentPage(this.slug).then(response => {
                this.content = response.data.content;
            });
        }
    },
})
