import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col mb-3 mb-md-0 mb-lg-3 mb-xl-0 d-none d-lg-block" }
const _hoisted_2 = { class: "button-block__title" }
const _hoisted_3 = { class: "button-block__content" }
const _hoisted_4 = { class: "button-block__button btn btn-link btn-link--left p-0" }
const _hoisted_5 = { class: "chevron-circle chevron-circle--right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "button-block",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('infoBtn')))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_icon, { icon: "chevronCircleLeft" })
        ]),
        _createTextVNode(" Bekijk " + _toDisplayString(_ctx.title.toLowerCase()), 1)
      ])
    ])
  ]))
}