
import {defineComponent} from 'vue'
import Icon from '@/components/Icon.vue';
import {Competence} from '@/data/api';

export default defineComponent({
    name: 'CompetenceDescriptionStand',
    components: {Icon},
    props: {
        competence: {type: Object as () => Competence, required: true}
    },
    data() {
        return {
            collapseOpen: true,
        }
    },
    methods: {
        infoCollapse() {
            this.collapseOpen = !this.collapseOpen;
        }
    }
})
