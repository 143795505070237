
import {defineComponent} from 'vue'
import {Statement} from '@/data/api';
import Icon from '@/components/Icon.vue';
import {Rating} from '@/data/enums';

export default defineComponent({
    name: 'RatingQuestion',
    components: {Icon},
    props: {
        statement: {type: Object as () => Statement, required: true}
    },
    computed: {
        selectedAnswer(): Rating | null {
            return this.$store.state.answers[this.statement.uuid] ?? null;
        },
        Rating() {
            return Rating;
        }
    },
    methods: {
        onRate: function (rating: Rating) {
            this.$store.commit('setAnswer', {
                uuid: this.statement.uuid,
                rating: rating
            });
        }
    },
})
