
import {defineComponent} from 'vue'
import {competenceMixin} from '@/router/competenceMixin';
import Icon from '@/components/Icon.vue';
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

export default defineComponent({
	name: 'ResultBlockExercises',
	components: {Icon, Carousel, Slide, Pagination, Navigation},
	mixins: [competenceMixin],
	props: {
		filePrefix: {type: String, default: ''}
	},
	data: () => ({
		slides: [] as string[]
	}),
	mounted() {
		if (this.competence) {
			for (const exercise of this.competence.reflectionContent.exercises) {
				this.slides.push(exercise.exercise);
			}
		}
	}
})
