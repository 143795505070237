import {Competence, Context, ContextGroup, Role} from '@/data/api';
import {Rating} from '@/data/enums';

// Any state values than rely and api's should initially be null
// That way we can easily tell if an api call failed or has not happened yet
export const state = {
    role: null as Role|null,
    context: null as Context|null,
    contextGroup: null as ContextGroup|null,
    answers: {} as {[key: string]: Rating},
    competences: null as Competence[]|null,
    steps: [] as string[]
};

export type State = typeof state;

// Persistent data will be saved to localstorage
export const persistent: readonly (keyof State)[] = ['role', 'context', 'contextGroup', 'answers'];
