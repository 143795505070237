
import '@/assets/scss/app.scss';
import '@/assets/scss/print.scss';
import {defineComponent} from 'vue';
import {persistent} from '@/store/state';
import HeaderMenu from '@/views/layout/HeaderMenu.vue';
import CookieBanner from '@/components/CookieBanner.vue';

export default defineComponent({
    name: 'ToolLoopbaanCompetenties',
    components: {CookieBanner, HeaderMenu},
    setup() {
        return {
            cookieEnabled: navigator.cookieEnabled
        }
    },
    watch: {
        $route() {
            window.scrollTo(0, 0);
        },
    },
    beforeCreate() {
        this.$store.commit('initialiseStore');
        this.$store.dispatch('fetchCompetences');
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            // Save data marked as "persistent" to localStorage
            const persistentState: { [key: string]: object } = {};

            for (const key of persistent) {
                const value = state[key];
                if (value) {
                    persistentState[key] = value;
                }
            }

            window.localStorage.setItem('store', JSON.stringify(persistentState));
        });
    }
})
