export enum Rating {
    Negative,
    Neutral,
    Positive
}

export enum SurveyStatus {
    NotStarted,
    InProgress,
    Completed
}

export enum CompetenceStatus {
    Disabled = 'disabled',
    Active = 'active',
    Done = 'done'
}