import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rating-question row" }
const _hoisted_2 = { class: "col-12 col-md-7 col-lg-12 col-xl-7" }
const _hoisted_3 = { class: "rating-question__content" }
const _hoisted_4 = { class: "col-12 col-md-5 col-lg-12 col-xl-5" }
const _hoisted_5 = { class: "rating-question__buttons d-flex justify-content-between justify-content-sm-end align-items-center flex-nowrap" }
const _hoisted_6 = { class: "plus" }
const _hoisted_7 = { class: "plusMinus" }
const _hoisted_8 = { class: "minus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.statement.question), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-rating btn-outline-secondary", {'selected': _ctx.selectedAnswer === _ctx.Rating.Positive }]),
          "aria-label": "positive",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onRate(_ctx.Rating.Positive)))
        }, [
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_component_icon, { icon: "plus" })
          ])
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-rating btn-outline-secondary", {'selected': _ctx.selectedAnswer === _ctx.Rating.Neutral }]),
          "aria-label": "neutral",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRate(_ctx.Rating.Neutral)))
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_icon, { icon: "plusMinus" })
          ])
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-rating btn-outline-secondary", {'selected': _ctx.selectedAnswer === _ctx.Rating.Negative }]),
          "aria-label": "negative",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onRate(_ctx.Rating.Negative)))
        }, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_icon, { icon: "minus" })
          ])
        ], 2)
      ])
    ])
  ]))
}