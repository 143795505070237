import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt';
import {createGtm} from '@gtm-support/vue-gtm';

const emitter = mitt();
const app = createApp(App);

declare module '@vue/runtime-core' {

}

app.use(
    createGtm({
        id: 'GTM-MS9LPSX',
        defer: true,
        compatibility: false,
        enabled: false,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false
    })
);

// Global Properties
app.config.globalProperties.emitter = emitter;
app.use(store).use(router).mount('#app');