
import {defineComponent} from 'vue'
import {competenceMixin} from '@/router/competenceMixin';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    name: 'ResultBlockWorkQuestions',
    components: { Icon },
    mixins: [competenceMixin],
    props: {
        filePrefix: {type: String, default: ''},
    }
})
