
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';
import SubmenuCompetence from '@/components/SubmenuCompetence.vue';
import {Competence} from '@/data/api';
import {navigationMixin} from '@/router/navigationMixin';
import SubmenuEntry from '@/components/SubmenuEntry.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import {SurveyStatus} from '@/data/enums';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import {path, route, RouteName, RoutePath} from '@/router';
import {RouteParams} from 'vue-router';

export default defineComponent({
    name: 'HeaderMenu',
    components: {TransitionExpand, SiteLogo, SubmenuCompetence, SubmenuEntry, Icon},
    mixins: [navigationMixin],
    data() {
        return {
            hamburgerOpen: false,
            inProgressRefs: [] as HTMLElement[],
            completedRefs: [] as HTMLElement[]
        }
    },
    computed: {
        SurveyStatus() {
            return SurveyStatus;
        },
        competences(): Competence[] | null {
            return this.$store.state.competences;
        },
        surveyStatus() {
            if (this.$route.name === 'ResultOverview' || this.$route.name === 'ResultCompetenceAdvice') {
                return SurveyStatus.Completed;
            } else if (this.$store.state.steps.includes(this.$route.path)) {
                return SurveyStatus.InProgress;
            }

            return SurveyStatus.NotStarted;
        },
        currentPage(): string {
            if (this.stepIndex >= 0) {
                return this.$store.state.steps[this.stepIndex];
            }

            const finalPage = path('/scan/resultaten');
            if (this.$store.state.steps.includes(finalPage)) {
                return finalPage;
            }

            return this.$store.state.steps[this.$store.state.steps.length - 1];
        },
    },
    watch: {
        $route() {
            this.hamburgerToggle(false);
        },
    },
    methods: {
        hamburgerToggle(force?: boolean) {
            this.hamburgerOpen = force ?? !this.hamburgerOpen;
            document.body.classList.toggle('drawer-open', this.hamburgerOpen);
        },
        route(name: RouteName, params?: RouteParams) {
            return route(name, params);
        },
        path(url: RoutePath) {
            return path(url);
        }
    },
})
