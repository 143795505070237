import {defineComponent} from 'vue';
import {Competence} from '@/data/api';

export const competenceMixin = defineComponent({
    computed: {
        competenceId(): number {
            const id = this.$route.params['id'];
            return typeof id === 'string' ? parseInt(id) : 0;
        },
        competence(): Competence|null|undefined {
            if (this.$store.state.competences) {
                return this.$store.state.competences.find(item => {
                    return item.id === this.competenceId;
                });
            }

            return null;
        }
    }
});