
import api, {Context, ContextGroup} from '@/data/api';
import {defineComponent} from 'vue'
import {navigationMixin} from '@/router/navigationMixin';
import SurveyNavigationButtons from '@/components/SurveyNavigationButtons.vue';

export default defineComponent({
	name: 'SelectContext',
	components: {SurveyNavigationButtons},
	mixins: [navigationMixin],
	data() {
		return {
			contextGroups: null as { [key: number]: ContextGroup } | null
		}
	},
	mounted() {
		const role = this.$store.state.role;

		if (role) {
			api.getContextGroups(role.id).then(response => {
				this.contextGroups = response.data;
			});
		} else {
			console.error('No role was set.')
			this.$router.push({name: 'SelectRole'});
		}
	},
	methods: {
		contextSelected: function (context: Context, contextGroup: ContextGroup) {
			this.$store.commit('setContext', {context, contextGroup});
			this.$store.dispatch('fetchCompetences').then(() => {
				const nextRoute = this.nextPage;
				if (nextRoute) {
					this.$router.push(nextRoute);
				}
			});
		}
	},
})
