import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info-stand shadow bg-white rounded"
}
const _hoisted_2 = { class: "small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$store.state.context && _ctx.$store.state.contextGroup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, " Ondersteuning bij " + _toDisplayString(_ctx.$store.state.contextGroup.groupName), 1),
        _createElementVNode("h6", null, "“" + _toDisplayString(_ctx.$store.state.context.name) + "”", 1)
      ]))
    : _createCommentVNode("", true)
}