import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.selected)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btnClick')))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: _ctx.url
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to"]))
}