import {createRouter, createWebHashHistory, RouteParams} from 'vue-router';
import {trackRouter} from 'vue-gtag-next';
import ContentPage from '@/views/ContentPage.vue';
import SelectRole from '@/views/SelectRole.vue';
import SelectContext from '@/views/SelectContext.vue';
import CompetenceQuestions from '@/views/CompetenceQuestions.vue';
import ResultOverview from '@/views/ResultOverview.vue';
import ResultCompetence from '@/views/ResultCompetence.vue';

const _routes = [
    { path: '/', name: 'Home', component: ContentPage },
    { path: '/scan/selecteer-rol', name: 'SelectRole', component: SelectRole },
    { path: '/scan/selecteer-context', name: 'SelectContext', component: SelectContext },
    { path: '/scan/competentie/:id', name: 'CompetenceQuestionList', component: CompetenceQuestions, meta: {keepScrollPos: true} },
    { path: '/scan/resultaten', name: 'ResultOverview', component: ResultOverview },
    { path: '/scan/resultaten/:id', name: 'ResultCompetenceAdvice', component: ResultCompetence },
    { path: '/:slug', name: 'ContentPage', component: ContentPage },
] as const;

export type RouteName = typeof _routes[number]['name'];
export type RoutePath = typeof _routes[number]['path'];

export const route = (routeName: RouteName, params?: RouteParams) => ({name: routeName, params: params});
export const path = (routePath: RoutePath) => routePath;

const router = createRouter({history: createWebHashHistory(), routes: [..._routes]});

trackRouter(router);

export default router;