
import {Role} from '@/data/api';
import api from '@/data/api';
import {defineComponent} from 'vue'

export default defineComponent({
	name: 'SelectRole',
	data() {
		return {
			roles: null as Role[]|null
		}
	},
	mounted() {
		api.getRoles().then(response => {
			this.roles = response.data;
		});
	},
	methods: {
		roleSelected(role: Role) {
			this.$store.commit('setRole', role);
			this.$router.push({name: 'SelectContext'});
		}
	},
})
