
import {defineComponent} from 'vue'
import {navigationMixin} from '@/router/navigationMixin';
import Icon from '@/components/Icon.vue';
import {RouterLink} from 'vue-router';

export default defineComponent({
    name: 'SurveyNavigationButtons',
    components: {Icon, RouterLink},
    mixins: [navigationMixin],
    props: {
        backwards: {type: Boolean, default: true},
        forwards: {type: Boolean, default: true}
    }
})
