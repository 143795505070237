import {defineComponent} from 'vue';

export const navigationMixin = defineComponent({
    computed: {
        stepIndex(): number {
            return this.$store.state.steps.indexOf(this.$route.path);
        },
        previousPage(): string|null {
            return (this.stepIndex > 0) ? this.$store.state.steps[this.stepIndex - 1] : null;
        },
        nextPage(): string|null {
            return (this.stepIndex + 1 < this.$store.state.steps.length) ? this.$store.state.steps[this.stepIndex + 1] : null;
        }
    }
});