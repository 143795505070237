import {CompetenceStatus, Rating} from '@/data/enums';
import {Competence, Context, ContextGroup, Role} from '@/data/api';
import {MutationTree} from 'vuex';
import {State} from '@/store/state';
import {path} from '@/router';

export type Mutations<S = State> = {
    'initialiseStore'(state: S): void;
    'setAnswer'(state: S, payload: {uuid: string; rating: Rating|null}): void;
    'setRole'(state: S, payload: Role|null): void;
    'setContext'(state: S, payload: {context: Context|null; contextGroup: ContextGroup|null}): void;
    'setCompetences'(state: S, payload: Competence[]): void;
}

export const mutations: MutationTree<State> & Mutations = {
    'initialiseStore'(state) {
        const store = localStorage.getItem('store');
        if(store) {
            this.replaceState(Object.assign(state, JSON.parse(store)));
        }
        _updateSurveyProgress(state);
    },
    'setAnswer'(state, answer) {
        if (answer.rating === null) {
            delete state.answers[answer.uuid];
        } else {
            state.answers[answer.uuid] = answer.rating;
        }
        _updateSurveyProgress(state);
    },
    'setRole'(state, role) {
        if (state.role !== role) {
            state.role = role;
            state.context = null;
            state.contextGroup = null;
            state.competences = null;
            _updateSurveyProgress(state);
        }
    },
    'setContext'(state, payload) {
        state.context = payload.context;
        state.contextGroup = payload.contextGroup;
        /// Should probably execute the fetchsurveycompetences action
    },
    'setCompetences'(state, competences) {
        state.competences = competences;
        _updateSurveyProgress(state);
    }
}

// A private helper function that almost every commit calls
const _updateSurveyProgress = (state: State) => {
    state.steps = [path('/scan/selecteer-rol')];

    if (state.role) {
        state.steps.push(path('/scan/selecteer-context'));

        if (state.competences) {
            let previousStatus: CompetenceStatus|undefined = CompetenceStatus.Done;

            let completed = 0;
            for (const i in state.competences) {
                let answered = 0;

                for (const uuid in state.competences[i].statements) {
                    const rating = state.answers[uuid] ?? '';
                    if (Object.values(Rating).includes(rating)) {
                        answered += 1;
                    } else if (answered > 0) {
                        break;
                    }
                }

                if (answered === 0 && previousStatus !== CompetenceStatus.Done) {
                    state.competences[i].status = CompetenceStatus.Disabled;
                } else if (answered === Object.values(state.competences[i].statements).length) {
                    state.competences[i].status = CompetenceStatus.Done;
                    completed += 1;
                } else {
                    state.competences[i].status = CompetenceStatus.Active;
                }

                if (state.competences[i].status !== CompetenceStatus.Disabled) {
                    state.steps.push(`/scan/competentie/${state.competences[i].id}`);
                }

                if (state.competences[i].status !== CompetenceStatus.Done) {
                    break;
                }

                previousStatus = state.competences[i].status;
            }

            if (completed === state.competences.length) {
                state.steps.push(path('/scan/resultaten'));
                for (const competence of state.competences) {
                    state.steps.push(`/scan/resultaten/${competence.id}`);
                }
            }
        }
    }
};
