import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "competence-result-navigation" }
const _hoisted_2 = { class: "competence-result-navigation__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rating_symbol = _resolveComponent("rating-symbol")!
  const _component_competence_nav_item = _resolveComponent("competence-nav-item")!
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["competence-result-navigation__inner d-flex flex-column w-100", {'show': _ctx.isOpen}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.competencesToDisplay, (comp, key) => {
        return (_openBlock(), _createBlock(_component_competence_nav_item, {
          key: key,
          to: `/scan/resultaten/${comp.id}`,
          class: "router-resultaat-competentie",
          selected: _ctx.isSelected(comp),
          url: `/scan/resultaten/${comp.id}`,
          onBtnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_rating_symbol, {
              rating: _ctx.getRating(comp)
            }, null, 8, ["rating"]),
            _createElementVNode("div", _hoisted_2, _toDisplayString(comp.name), 1)
          ]),
          _: 2
        }, 1032, ["to", "selected", "url"]))
      }), 128))
    ], 2),
    _createElementVNode("button", {
      class: _normalizeClass(["more-info-stand__button", {'show': _ctx.isOpen}]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _createElementVNode("span", null, [
        _createVNode(_component_icon, { icon: "chevronDown" })
      ])
    ], 2)
  ]))
}